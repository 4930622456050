// client/src/pages/Subscribe/index.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CheckIcon } from '@heroicons/react/outline';
import { fetchSubscriptionStatus } from '../../store/thunks/subscriptionThunks';
import CheckoutForm from './CheckoutForm';  // Assuming you've moved CheckoutForm to its own file
import { selectSubscription } from '../../store/slices/subscriptionSlice';
import { PLAN_FEATURES } from '../../config/planFeatures';
import logger from '../../services/logger'

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// logger.info('Stripe Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY?.substring(0, 8) + '...');


const Subscribe = () => {
  const dispatch = useDispatch();
  const [billingInterval, setBillingInterval] = useState('monthly');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();
  const subscription = useSelector(selectSubscription);
  
  // In the useEffect where we check subscription status
  useEffect(() => {
    dispatch(fetchSubscriptionStatus())
    .then((subscriptionData) => {
      // Add check for trial status
      if (subscriptionData?.status === 'active' || 
          subscriptionData?.status === 'trialing') {
        // Add small delay to ensure state is updated
        setTimeout(() => {
          navigate('/dashboard');  // Changed from /subscription to /dashboard
        }, 500);
      }
    })
    .catch(error => logger.error('Error fetching subscription:', error));
  }, [dispatch, navigate]);

  // Also update the subscription effect
  useEffect(() => {
    if (subscription?.status === 'active' || 
        subscription?.status === 'trialing') {
      navigate('/dashboard');  // Changed from /subscription to /dashboard
    }
  }, [subscription, navigate]);
    
  const plans = Object.values(PLAN_FEATURES);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-blue-600">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
              Choose your plan
            </h1>
            <p className="mt-4 text-xl text-blue-100">
              Start building amazing projects today
            </p>
          </div>

          {/* Billing Toggle */}
          <div className="mt-12 flex justify-center">
            <div className="relative self-center bg-blue-700 rounded-lg p-0.5 flex sm:mt-8">
              <button
                type="button"
                className={`relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none px-8 ${
                  billingInterval === 'monthly'
                    ? 'bg-white border-blue-600 text-blue-600'
                    : 'border border-transparent text-blue-100'
                }`}
                onClick={() => setBillingInterval('monthly')}
              >
                Monthly billing
              </button>
              <button
                type="button"
                className={`relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none px-8 ${
                  billingInterval === 'yearly'
                    ? 'bg-white border-blue-600 text-blue-600'
                    : 'border border-transparent text-blue-100'
                }`}
                onClick={() => setBillingInterval('yearly')}
              >
                Yearly billing
                <span className="absolute -top-3 right-0 bg-green-500 text-white text-xs px-2 py-0.5 rounded-full">
                  Save 15%
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-24">
        <div className="grid gap-6 lg:grid-cols-3 lg:gap-8">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`rounded-lg shadow-lg bg-white divide-y divide-gray-200 ${
                plan.highlighted ? 'ring-2 ring-blue-600' : ''
              }`}
            >
              <div className="p-6">
                {plan.trial?.eligible && billingInterval === 'monthly' && (
                  <div className="mb-4">
                    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
                      7-day free trial
                    </span>
                  </div>
                )}
                <h2 className="text-2xl font-semibold text-gray-900">{plan.name}</h2>
                <p className="mt-4 text-sm text-gray-500">{plan.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    ${billingInterval === 'monthly' ? plan.monthly : plan.yearly}
                  </span>
                  <span className="text-base font-medium text-gray-500">
                    /{billingInterval === 'monthly' ? 'mo' : 'yr'}
                  </span>
                </p>
                {selectedPlan?.id === plan.id ? (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm 
                      planId={plan.id} 
                      interval={billingInterval}
                      onCancel={() => setSelectedPlan(null)}
                    />
                  </Elements>
                ) : (
                  <button
                    onClick={() => setSelectedPlan(plan)}
                    className="mt-8 block w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md py-2 text-sm text-center"
                  >
                    Select Plan
                  </button>
                )}
              </div>
              <div className="px-6 pt-6 pb-8">
                <h3 className="text-xs font-semibold text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {plan.features?.featureList?.map((feature, index) => (
                    <li key={index} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {[
              {
                question: 'Can I cancel anytime?',
                answer: 'Yes, you can cancel your subscription at any time. You\'ll continue to have access until the end of your billing period.'
              },
              {
                question: 'What payment methods do you accept?',
                answer: 'We accept all major credit cards and debit cards.'
              }
            ].map((faq) => (
              <div key={faq.question} className="pt-6">
                <dt className="text-lg font-medium text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;