import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from '../common/LoadingScreen';
import api from '../../services/api';
import { setSubscriptionData } from '../../store/slices/subscriptionSlice';
import logger from '../../services/logger';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const subscription = useSelector(state => state.subscription);

  useEffect(() => {
    // Only try to create/fetch subscription if user is authenticated and we don't have one
    const initializeSubscription = async () => {
      if (isAuthenticated && !subscription?.currentPlan) {
        try {
          // Try to fetch existing subscription
          const response = await api.get('/api/v1/subscription/status');
          dispatch(setSubscriptionData(response.data));
        } catch (error) {
          if (error.response?.status === 404) {
            // No subscription found - create free tier
            try {
              const createResponse = await api.post('/api/v1/subscription/create', {
                plan_id: 'free',
                auto_create: true
              });
              
              dispatch(setSubscriptionData({
                currentPlan: 'free',
                status: 'active',
                interval: 'monthly',
                currentPeriodEnd: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(),
                currentPeriodStart: new Date().toISOString(),
                cancelAtPeriodEnd: false,
                id: createResponse.data.subscription_id
              }));
            } catch (createError) {
              logger.error('Error creating free subscription:', createError);
            }
          }
        }
      }
    };

    initializeSubscription();
  }, [isAuthenticated, subscription?.currentPlan, dispatch]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    // Redirect to login but save the attempted URL
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;