import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import AuthLayout from './components/AuthLayout';
import LoadingButton from '../../components/common/LoadingButton';
import PasswordStrength from '../../components/common/PasswordStrength';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { setCredentials, setLoading, setError } from '../../store/slices/authSlice';
import { addNotification } from '../../store/slices/uiSlice';
import { validatePassword } from '../../utils/validationRules';
import { useAuth0 } from '@auth0/auth0-react';
import logger from '../../services/logger';

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  // Get state from Redux
  const isLoading = useAppSelector(state => state.auth.loading);
  const authError = useAppSelector(state => state.auth.error);
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  // Local state
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false
  });
  const [formErrors, setFormErrors] = useState({});

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    
    const passwordValidation = validatePassword(formData.password);
    if (passwordValidation.error) {
      errors.password = passwordValidation.error;
    }
    
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    
    if (!formData.agreeToTerms) {
      errors.agreeToTerms = 'You must agree to the terms and conditions';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Update password strength when password changes
    if (name === 'password') {
      const validation = validatePassword(value);
      setPasswordStrength(validation.strength);
    }

    // Clear error when field is modified
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    try {
      logger.info('Starting Auth0 signup process...', {
        email: formData.email
      });
  
      // Use Auth0's signup-specific parameters
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          prompt: 'login',
        },
        connection: 'Username-Password-Authentication',
        email: formData.email,
        password: formData.password,
        username: formData.name,
        user_metadata: {
          name: formData.name
        }
      });
  
      logger.info('Signup redirect initiated');
  
    } catch (error) {
      logger.error('Signup redirect error:', error);
      dispatch(setError('Failed to start signup process'));
      dispatch(addNotification({
        id: Date.now(),
        type: 'error',
        message: 'Failed to create account. Please try again.'
      }));
    }
  };

  // Update form input and label styles
  const inputClasses = (error) => `appearance-none block w-full px-3 py-2 border rounded-md shadow-sm 
  ${error ? 'border-red-300' : 'border-gray-700'} 
  bg-gray-800 text-white 
  focus:outline-none focus:ring-blue-500 focus:border-blue-500
  placeholder-gray-500`;

  // Update motion.div error styling
  const errorStyles = `mb-4 p-3 rounded-md bg-red-900 border border-red-700 text-red-200`;

  // Update label classes
  const labelClasses = `block text-sm font-medium text-gray-300`;

  // Then in your JSX, update color classes:
  return (
  <AuthLayout 
    title="Create your account"
    subtitle={
      <span className="text-gray-400">
        Already have an account?{' '}
        <Link to="/signin" className="text-blue-400 hover:text-blue-300">
          Sign in
        </Link>
      </span>
    }
  >
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {authError && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          className={errorStyles}
        >
          <p className="text-sm text-red-200">{authError}</p>
        </motion.div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Field */}
        <div>
          <label htmlFor="name" className={labelClasses}>
            Full Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={inputClasses(formErrors.name)}
            />
            {formErrors.name && (
              <p className="mt-1 text-sm text-red-400">{formErrors.name}</p>
            )}
          </div>
        </div>

        {/* Email Field */}
        <div>
          <label htmlFor="email" className={labelClasses}>
            Email address
          </label>
          <div className="mt-1">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={inputClasses(formErrors.email)}
            />
            {formErrors.email && (
              <p className="mt-1 text-sm text-red-400">{formErrors.email}</p>
            )}
          </div>
        </div>

        {/* Password Field */}
        <div>
          <label htmlFor="password" className={labelClasses}>
            Password
          </label>
          <div className="mt-1 relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={inputClasses(formErrors.password)}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeOffIcon className="h-5 w-5 text-gray-400" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" />
              )}
            </button>
          </div>
          {passwordStrength && (
            <PasswordStrength strength={passwordStrength} className="mt-2" />
          )}
          {formErrors.password && (
            <p className="mt-1 text-sm text-red-400">{formErrors.password}</p>
          )}
        </div>

        {/* Confirm Password Field */}
        <div>
          <label htmlFor="confirmPassword" className={labelClasses}>
            Confirm Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className={inputClasses(formErrors.confirmPassword)}
            />
            {formErrors.confirmPassword && (
              <p className="mt-1 text-sm text-red-400">{formErrors.confirmPassword}</p>
            )}
          </div>
        </div>

        {/* Terms and Conditions */}
        <div>
          <div className="flex items-center">
            <input
              id="agreeToTerms"
              name="agreeToTerms"
              type="checkbox"
              checked={formData.agreeToTerms}
              onChange={handleChange}
              className={`h-4 w-4 rounded border-gray-600 bg-gray-700 text-blue-500 focus:ring-blue-500 ${
                formErrors.agreeToTerms ? 'border-red-400' : ''
              }`}
            />
            <label htmlFor="agreeToTerms" className="ml-2 block text-sm text-gray-300">
              I agree to the{' '}
              <Link to="/terms" className="text-blue-400 hover:text-blue-300">
                Terms and Conditions
              </Link>
            </label>
          </div>
          {formErrors.agreeToTerms && (
            <p className="mt-1 text-sm text-red-400">{formErrors.agreeToTerms}</p>
          )}
        </div>

        {/* Submit Button - keep the original button styles for consistency */}
        <LoadingButton
          type="submit"
          isLoading={isLoading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Create Account
        </LoadingButton>
      </form>
    </motion.div>
  </AuthLayout>
  );
  };

export default SignUp;