import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slices/authSlice';
import { setSubscriptionData } from '../store/slices/subscriptionSlice';
import logger from '../services/logger';
import api from '../services/api';

function AuthStateManager() {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeUser = async () => {
      if (isAuthenticated && user) {
        try {
          logger.info('Initializing authenticated user:', { 
            userId: user.sub, 
            email: user.email 
          });
          
          const token = await getAccessTokenSilently();
          dispatch(setCredentials({ token, user }));

          // Create user in our database
          try {
            logger.info('Attempting to create user in database');
            const createUserResponse = await api.post('/api/v1/users/create', {
              id: user.sub,
              email: user.email,
              is_active: true
            });
            logger.info('User creation response:', createUserResponse.data);
          } catch (error) {
            // If error is not 409 (Conflict/Already exists), log it
            if (error.response?.status !== 409) {
              logger.error('Error creating user:', error.response?.data || error.message);
            } else {
              logger.info('User already exists in database');
            }
          }

          // Try to fetch existing subscription
          try {
            logger.info('Fetching subscription status');
            const subscriptionResponse = await api.get('/api/v1/subscription/status');
            dispatch(setSubscriptionData(subscriptionResponse.data));
            logger.info('Subscription fetched:', subscriptionResponse.data);
          } catch (error) {
            if (error.response?.status === 404) {
              logger.info('No subscription found, creating free tier subscription');
              // No subscription found - create free tier subscription
              const createResponse = await api.post('/api/v1/subscription/create', {
                plan_id: 'free',
                auto_create: true
              });
              
              dispatch(setSubscriptionData({
                currentPlan: 'free',
                status: 'active',
                interval: 'monthly',
                currentPeriodEnd: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(),
                currentPeriodStart: new Date().toISOString(),
                cancelAtPeriodEnd: false,
                id: createResponse.data.subscription_id,
                userId: user.sub
              }));
              logger.info('Free tier subscription created');
            } else {
              logger.error('Error fetching subscription:', error.response?.data || error.message);
            }
          }
        } catch (error) {
          logger.error('Error in user initialization:', error.response?.data || error.message);
        }
      }
    };

    initializeUser();
  }, [getAccessTokenSilently, isAuthenticated, dispatch, user]);

  return null;
}

export default AuthStateManager;