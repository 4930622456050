// src/store/thunks/chatThunks.js
import api from '../../services/api';
import { setChatLoading, setChatError, addMessage } from '../slices/chatSlice';
import { addNotification } from '../slices/uiSlice';
import { fetchChatsStart, fetchChatsSuccess, fetchChatsError } from '../slices/chatHistorySlice';
import { toast } from 'react-hot-toast';
import logger from '../../services/logger';

const RETRY_DELAY = 3000; // 3 seconds
const MAX_RETRIES = 2;

const handleResponse = (response) => {
  // Check if response contains a warning about partial data
  if (response.data.metadata?.partial_data) {
    toast.warning(
      'Some transactions might be missing due to the large date range. Consider narrowing your search for more accurate results.',
      { duration: 6000 }
    );
  }
  return response.data;
};

// src/store/thunks/chatThunks.js
export const sendChatMessage = ({ 
  message, 
  accountId, 
  modelId, 
  provider, 
  chatId,
  dateRange,
  retryCount = 0
}) => async (dispatch) => {
  try {
    dispatch(setChatLoading(true));

    const payload = {
      message,
      account_id: accountId,
      model_provider: provider,
      model_id: modelId,
      chat_id: chatId,
      ...(dateRange?.startDate && dateRange?.endDate && {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate
      })
    };

    // Pass retry count as query parameter instead of header
    const response = await api.post(
      `/api/v1/chat/message?retry_count=${retryCount}`, 
      payload
    );
    
    const data = handleResponse(response);
    
    dispatch(addMessage({
      role: 'assistant',
      content: data.message,
      metadata: data.metadata
    }));
    
    return data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || error.message || 'Failed to send message';
    
    // Handle retry cases for timeout or server errors
    if ((error.code === 'ECONNABORTED' || error.response?.status === 500) && retryCount < MAX_RETRIES) {
      const retryAttempt = retryCount + 1;
      toast.loading(
        `Processing large data volume. Retry ${retryAttempt} of ${MAX_RETRIES}...`, 
        { duration: RETRY_DELAY }
      );
      
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      
      // Retry with incremented count
      return dispatch(sendChatMessage({
        message,
        accountId,
        modelId,
        provider,
        chatId,
        dateRange,
        retryCount: retryAttempt
      }));
    }

    // Handle specific error cases
    if (error.response?.status === 403) {
      toast.error('You do not have access to this model. Please upgrade your plan.');
    } else if (error.code === 'ECONNABORTED') {
      toast.error('Request timed out. Try reducing your date range or try again.');
    } else if (error.response?.status === 500) {
      toast.error('Server is busy processing data. Please try again in a moment.');
    } else {
      toast.error(errorMessage);
    }
    
    throw error;
  } finally {
    dispatch(setChatLoading(false));
  }
};

// Keep your existing fetchChatHistory implementation
export const fetchChatHistory = () => async (dispatch) => {
  dispatch(fetchChatsStart());
  try {
    const response = await api.get('/api/v1/chat/chats');
    dispatch(fetchChatsSuccess(response.data));
    return response.data;
  } catch (error) {
    logger.error('Error in fetchChatHistory:', error);
    dispatch(fetchChatsError(error.message));
    throw error;
  }
};