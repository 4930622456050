// client/src/services/api.js
import axios from 'axios';
import { store } from '../store';
import logger from '../services/logger'

const BASE_TIMEOUT = 60000; // Keep your existing 10s timeout as base

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: BASE_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': process.env.REACT_APP_API_KEY,
    'Accept': 'application/json',
  }
});

api.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // If this is a chat request with a date range, adjust timeout
  if (config.url?.includes('/api/v1/chat/message') && config.data) {
    const { start_date, end_date } = config.data;
    if (start_date && end_date) {
      const start = new Date(start_date);
      const end = new Date(end_date);
      const diffMonths = (end.getFullYear() - start.getFullYear()) * 12 + 
                        (end.getMonth() - start.getMonth());
      
      // Add 5 seconds per month of data, up to a maximum of 30 seconds
      const additionalTime = Math.min(diffMonths * 5000, 20000);
      config.timeout = BASE_TIMEOUT + additionalTime;
    }
  }

  // Add retry count to headers if present
  if (config.retryCount) {
    config.headers['X-Retry-Count'] = config.retryCount;
  }

  // Log request details in development
  if (process.env.NODE_ENV === 'development') {
    logger.info('API Request:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data,
      timeout: config.timeout
    });
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log error details
    logger.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      timeout: error.config?.timeout
    });

    // Enhance error message for timeout cases
    if (error.code === 'ECONNABORTED') {
      error.message = 'Request timed out due to large data volume. Try reducing your date range.';
    }

    return Promise.reject(error);
  }
);

export default api;