import api from './api';

const logger = {
  info: async (message, data = {}) => {
    try {
      await api.post('/api/v1/logs/store_frontend_logs', {
        level: 'info',
        message,
        data,
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      // Fallback to console if API call fails
      console.info(message, data);
    }
  },

  error: async (message, error = null) => {
    try {
      await api.post('/api/v1/logs/store_frontend_logs', {
        level: 'error',
        message,
        error: error?.message || error,
        stack: error?.stack,
        timestamp: new Date().toISOString()
      });
    } catch (err) {
      // Fallback to console if API call fails
      console.error(message, error);
    }
  }
};

export default logger;