import React, { useState } from 'react';
import { XIcon, CheckIcon } from '@heroicons/react/outline';
import { PLAN_FEATURES } from '../../config/planFeatures';
import logger from '../../services/logger'

const ChangePlanModal = ({ isOpen, onClose, onChangePlan, currentPlan, subscription }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Filter out the free plan from available plans when upgrading
  const plans = Object.values(PLAN_FEATURES).filter(plan => 
    currentPlan === 'free' ? plan.id !== 'free' : true
  );

  const isValidPlanChange = () => {
    if (!selectedPlan) return false;
    if (!subscription) return false;

    const isFreeUpgrade = currentPlan === 'free' && selectedPlan !== 'free';
    if (isFreeUpgrade) return true;

    const isSamePlan = selectedPlan === currentPlan;
    return !isSamePlan;
  };

  const handleSubmit = async () => {
    if (!selectedPlan) return;
    
    setLoading(true);
    try {
      await onChangePlan(selectedPlan);
      onClose();
    } catch (error) {
      logger.error('Error changing plan:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderPlanCard = (plan) => {
    const isCurrentPlan = currentPlan === plan.id;
    const isFreeUpgrade = currentPlan === 'free' && plan.id !== 'free';

    return (
      <div
        key={plan.id}
        onClick={() => setSelectedPlan(plan.id)}
        className={`
          relative rounded-lg p-6 border-2 cursor-pointer transition-all
          ${selectedPlan === plan.id ? 'border-blue-500 shadow-lg' : 'border-gray-200'}
          ${isCurrentPlan ? 'bg-blue-50' : 'bg-white'}
          hover:shadow-md
        `}
      >
        {isCurrentPlan && (
          <span className="absolute top-2 right-2 text-blue-500 text-sm font-medium">
            Current Plan
          </span>
        )}

        {isFreeUpgrade && (
          <span className="absolute top-2 right-2 text-green-500 text-sm font-medium">
            Recommended Upgrade
          </span>
        )}

        <h4 className="text-xl font-semibold text-gray-900">{plan.name}</h4>
        <p className="mt-2 text-gray-500">{plan.description}</p>
        
        <p className="mt-4">
          <span className="text-3xl font-bold text-gray-900">
            ${plan.monthly}
          </span>
          <span className="text-gray-500">/month</span>
        </p>

        {plan.id !== 'free' && (
          <p className="mt-1 text-sm text-gray-500">
            or ${plan.yearly}/year
          </p>
        )}

        <ul className="mt-6 space-y-4">
          {plan.features.featureList.map((feature, index) => (
            <li key={index} className="flex items-start">
              <CheckIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={onClose}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold text-gray-900">
                {currentPlan === 'free' ? 'Upgrade Your Plan' : 'Change Subscription Plan'}
              </h3>
              <p className="mt-2 text-gray-600">
                {currentPlan === 'free' 
                  ? 'Choose a premium plan to unlock more features' 
                  : 'Select your new plan below'}
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
              {plans.map(renderPlanCard)}
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              disabled={!isValidPlanChange() || loading}
              onClick={handleSubmit}
              className={`
                w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 
                bg-blue-600 text-base font-medium text-white hover:bg-blue-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                sm:ml-3 sm:w-auto sm:text-sm
                ${(!isValidPlanChange() || loading) ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              {loading ? 'Processing...' : currentPlan === 'free' ? 'Upgrade Plan' : 'Change Plan'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 
                bg-white text-base font-medium text-gray-700 hover:bg-gray-50 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanModal;