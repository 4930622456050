import logger from '../../../services/logger'

// src/pages/Dashboard/components/ModelSelector.jsx
const ModelSelector = ({ models, selectedModel, onSelect, currentPlan }) => {
  logger.info("Available models are ", models)
  return (
    <select
      value={selectedModel?.id || ''}
      onChange={(e) => {
        const model = models.find(m => m.id === e.target.value);
        onSelect(model);
      }}
      className="block w-60 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-gray-900 dark:text-gray-100"
    >
      {models.map(model => (
        <option 
          key={model.id} 
          value={model.id}
          disabled={!model.isAvailable}
        >
          {model.name} {!model.isAvailable && `(Requires ${model.requiredPlan})`}
        </option>
      ))}
    </select>
  );
};

export default ModelSelector;