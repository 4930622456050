import api from '../../services/api';
import { fetchPaymentMethod } from './paymentMethodThunks';
import {
  setSubscriptionLoading,
  setSubscriptionError,
  setSubscriptionData,
  setPaymentProcessing,
  clearSubscription,
  setPaymentError
} from '../slices/subscriptionSlice';
import logger from '../../services/logger';


export const createSubscription = (planId, paymentMethodId, interval) => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    dispatch(setPaymentProcessing(true));

    const response = await api.post('/api/v1/subscription/create', {
      plan_id: planId,
      payment_method_id: paymentMethodId,
      interval
    });

    // If payment is required, return immediately for frontend confirmation
    if (response.data.client_secret) {
      return response.data;
    }

    // If no payment confirmation needed, fetch status
    const subscriptionStatus = await dispatch(fetchSubscriptionStatus());
    return {
      ...response.data,
      subscriptionStatus
    };
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to create subscription'));
    dispatch(setPaymentError(error.response?.data?.detail || 'Payment processing failed'));
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
    dispatch(setPaymentProcessing(false));
  }
};

// in store/thunks/subscriptionThunks.js
export const fetchSubscriptionStatus = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.get('/api/v1/subscription/status');

    const subscriptionData = {
      currentPlan: response.data.plan,
      status: response.data.status,
      interval: response.data.interval,
      currentPeriodEnd: new Date(response.data.current_period_end).toISOString(),
      currentPeriodStart: new Date(response.data.current_period_start).toISOString(),
      cancelAtPeriodEnd: response.data.cancel_at_period_end,
      id: response.data.id,
      userId: response.data.user_id  // Add this
    };

    dispatch(setSubscriptionData(subscriptionData));
    return subscriptionData;
  } catch (error) {
    logger.error('Error fetching subscription:', error);
    let errorMessage = 'Failed to fetch subscription';
    
    if (error.response?.status === 404) {
      errorMessage = 'No subscription found';
    } else if (!error.response) {
      errorMessage = 'Network error. Please check your connection.';
    }
    
    dispatch(setSubscriptionError(errorMessage));
    // Don't throw the error, return null instead
    return null;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

// Cancel subscription
export const cancelSubscription = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post('/api/v1/subscription/cancel');
    await dispatch(fetchSubscriptionStatus());
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to cancel subscription'));
    throw error;
  }
};

export const changePlan = (planId, toastHandlers) => async (dispatch) => {
  const loadingToastId = toastHandlers?.loading?.start?.('Changing plan...');
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.post(`/api/v1/subscription/change-plan/${planId}`);
    
    if (response.data.status === 'requires_payment' || response.data.requires_payment) {
      return {
        requiresPayment: true,
        clientSecret: response.data.client_secret,
        subscriptionId: response.data.subscription_id
      };
    }
    
    await dispatch(fetchSubscriptionStatus());
    toastHandlers?.loading?.dismiss?.(loadingToastId);
    toastHandlers?.success?.planChanged?.(planId);
    return response.data;
    
  } catch (error) {
    toastHandlers?.loading?.dismiss?.(loadingToastId);
    
    if (error.response?.status === 402 || 
        error.response?.headers?.['x-error-type'] === 'PAYMENT_METHOD_REQUIRED') {
      return {
        requiresPaymentMethod: true,
        isTrialConversion: true,
        message: error.response?.data?.detail || "Please add a payment method to continue"
      };
    }
    
    toastHandlers?.error?.planChange?.(error);
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

export const updatePaymentMethod = (paymentMethodId, toastHandlers) => async (dispatch) => {
  const loadingToastId = toastHandlers?.loading?.start?.('Updating payment method...');
  try {
    dispatch(setSubscriptionLoading(true));
    
    await api.post('/api/v1/subscription/update-payment-method', {
      payment_method_id: paymentMethodId
    });
    
    // Refresh subscription and payment info
    await dispatch(fetchSubscriptionStatus());
    if (typeof fetchPaymentMethod === 'function') {
      await dispatch(fetchPaymentMethod());
    }
    
    toastHandlers?.loading?.dismiss?.(loadingToastId);
    toastHandlers?.success?.paymentUpdated?.();
    
  } catch (error) {
    toastHandlers?.loading?.dismiss?.(loadingToastId);
    toastHandlers?.error?.paymentUpdate?.(error);
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to update payment method'));
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

// Add to your existing thunks
// Reactivate subscription
export const reactivateSubscription = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post('/api/v1/subscription/reactivate');
    
    // Fetch updated subscription status after reactivation
    const updatedSubscription = await dispatch(fetchSubscriptionStatus());
    return updatedSubscription;
  } catch (error) {
    dispatch(setSubscriptionError(error.response?.data?.detail || 'Failed to reactivate subscription'));
    throw error;
  }
};

// In store/thunks/subscriptionThunks.js
export const getBillingHistory = () => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    const response = await api.get('/api/v1/subscription/billing-history');
    return response.data;  // Return the data directly
  } catch (error) {
    dispatch(setSubscriptionError('Failed to fetch billing history'));
    throw error;
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

export const cleanupFailedSubscription = (subscriptionId) => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading(true));
    await api.post(`/api/v1/subscription/cleanup/${subscriptionId}`);
    dispatch(clearSubscription());
  } catch (error) {
    logger.error('Error cleaning up subscription:', error);
    // Don't show error to user since this is cleanup
  } finally {
    dispatch(setSubscriptionLoading(false));
  }
};

export const confirmSubscriptionPayment = (client_secret) => async (dispatch) => {
  try {
    dispatch(setPaymentProcessing(true));
    
    // Note: actual payment confirmation happens in CheckoutForm component
    // This thunk is for tracking the state
    
    // Get final subscription status
    const subscriptionStatus = await dispatch(fetchSubscriptionStatus());
    
    if (!subscriptionStatus || subscriptionStatus.status !== 'active') {
      throw new Error('Subscription activation failed');
    }

    return subscriptionStatus;
  } catch (error) {
    dispatch(setPaymentError(error.message || 'Payment confirmation failed'));
    throw error;
  } finally {
    dispatch(setPaymentProcessing(false));
  }
};