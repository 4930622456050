// store/thunks/analyticsThunks.js
import api from '../../services/api';
import {
  setAnalyticsSummary,
  setLoading,
  setError
} from '../slices/analyticsSlice';
import logger from '../../services/logger';

export const fetchAnalyticsSummary = (timeRange = '7d') => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/api/v1/analytics/summary');
    dispatch(setAnalyticsSummary(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.message));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};

export const trackAnalytics = (analyticsData) => async (dispatch) => {
  try {
    await api.post('/api/v1/analytics/track', analyticsData);
  } catch (error) {
    logger.error('Failed to track analytics:', error);
    // Optionally dispatch an error - depends if you want to show tracking errors to users
  }
};