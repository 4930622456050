import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankAccounts, disconnectBank } from '../../../store/thunks/bankThunks';
import { selectBankAccounts, selectBankLoading, selectBankError } from '../../../store/slices/bankSlice';
import { CreditCard, Wallet, Building, AlertCircle, Trash2 } from 'lucide-react';
import DisconnectModal from './DisconnectModal';
import logger from '../../../services/logger';

const getAccountIcon = (accountType) => {
  switch (accountType.toLowerCase()) {
    case 'checking':
      return <Wallet className="h-5 w-5 text-blue-500" />;
    case 'savings':
      return <Building className="h-5 w-5 text-green-500" />;
    default:
      return <CreditCard className="h-5 w-5 text-gray-500" />;
  }
};

const BankAccountsList = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(selectBankAccounts);
  const isLoading = useSelector(selectBankLoading);
  const error = useSelector(selectBankError);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    dispatch(fetchBankAccounts());
  }, [dispatch]);

  // Group accounts by bank name
  const groupedAccounts = accounts.reduce((groups, account) => {
    const group = groups[account.bank_name] || [];
    group.push(account);
    groups[account.bank_name] = group;
    return groups;
  }, {});

  const handleDisconnectClick = (bankName) => {
    setSelectedBank(bankName);
    setModalOpen(true);
  };

  const handleConfirmDisconnect = async () => {
    try {
      // Find the connection ID for the selected bank
      const bankAccounts = groupedAccounts[selectedBank];
      if (!bankAccounts || bankAccounts.length === 0) return;
      
      // Get connection ID from the first account
      const connectionId = bankAccounts[0].connection_id;
      
      await dispatch(disconnectBank(connectionId));
      setModalOpen(false);
      setSelectedBank(null);
      
    } catch (error) {
      logger.error('Error disconnecting bank:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-md p-4 mt-4">
        <div className="flex items-start">
          <AlertCircle className="h-5 w-5 text-red-500 mt-0.5 mr-2" />
          <p className="text-sm text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  if (!accounts.length) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">No bank accounts connected yet.</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <h2 className="text-lg font-semibold text-gray-900">Connected Accounts</h2>
      
      {Object.entries(groupedAccounts).map(([bankName, bankAccounts]) => (
        // Update the account group container
        <div key={bankName} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
          <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">{bankName}</h3>
            <button
              onClick={() => handleDisconnectClick(bankName)}
              className="text-gray-400 hover:text-red-500 dark:hover:text-red-400 p-2 rounded-full hover:bg-red-50 dark:hover:bg-red-900/30 transition-colors"
              title={`Disconnect ${bankName}`}
            >
              <Trash2 className="h-5 w-5" />
            </button>
          </div>
          
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {bankAccounts.map((account) => (
              <div key={account.id} className="px-6 py-4 flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  {getAccountIcon(account.account_type)}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      {account.account_name}
                    </h4>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      {account.account_type.charAt(0).toUpperCase() + 
                      account.account_type.slice(1)}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-900 dark:text-white">****{account.account_number_last4}</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Connected {new Date(account.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <DisconnectModal
        isOpen={modalOpen}
        bankName={selectedBank}
        onConfirm={handleConfirmDisconnect}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  );
};

export default BankAccountsList;