import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import { PLAN_FEATURES, MODEL_REQUIREMENTS } from '../../config/planFeatures';
import logger from '../../services/logger';


// Replace your existing requirements with:
const SUBSCRIPTION_REQUIREMENTS = {};
Object.entries(PLAN_FEATURES).forEach(([planId, plan]) => {
  // Access routes through the nested features object
  const routes = plan.features?.routes || [];
  routes.forEach(route => {
    if (!SUBSCRIPTION_REQUIREMENTS[route]) {
      SUBSCRIPTION_REQUIREMENTS[route] = [];
    }
    SUBSCRIPTION_REQUIREMENTS[route].push(planId);
  });
});

// Routes that are accessible during grace period after subscription expires
const GRACE_PERIOD_ROUTES = ['/dashboard', '/settings'];

const SubscriptionGuard = ({ children }) => {
  const location = useLocation();
  const subscription = useAppSelector(state => state.subscription);
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  // Add to isValidSubscription
  const canUseModel = (modelName) => {
    const requiredPlans = MODEL_REQUIREMENTS[modelName];
    return requiredPlans?.includes(subscription.currentPlan);
  };

  
  // logger.info('SubscriptionGuard Check:', {
  //   subscription,
  //   currentPath: location.pathname,
  //   requirements: SUBSCRIPTION_REQUIREMENTS[location.pathname]
  // });

  const getCurrentPath = () => {
    // Get the base path without query parameters
    return '/' + location.pathname.split('/')[1];
  };

  const isValidSubscription = () => {
    if (!subscription) return false;
    
    const currentPath = getCurrentPath();
    const requiredPlans = SUBSCRIPTION_REQUIREMENTS[currentPath];
    
    // If no requirements specified, allow access
    if (!requiredPlans) return true;
    
    // Check if user's plan is in the required plans list
    const hasPlan = requiredPlans.includes(subscription.currentPlan);
    
    // Check subscription status
    const isActive = subscription.status === 'active';
    const isCanceled = subscription.cancelAtPeriodEnd;
    
    // Calculate if within grace period (7 days after expiration)
    const periodEnd = new Date(subscription.currentPeriodEnd);
    const now = new Date();
    const gracePeriodEnd = new Date(periodEnd);
    gracePeriodEnd.setDate(gracePeriodEnd.getDate() + 7);
    const isInGracePeriod = now <= gracePeriodEnd;
    
    // logger.info('Subscription Validation:', {
    //   hasPlan,
    //   isActive,
    //   isCanceled,
    //   isInGracePeriod,
    //   currentPath,
    //   requiredPlans
    // });
    
    // Allow access if:
    // 1. Subscription is active and has required plan
    // 2. Subscription is canceled but hasn't ended yet
    // 3. Within grace period for certain routes
    return (
      (isActive && hasPlan) ||
      (isCanceled && now <= periodEnd && hasPlan) ||
      (isInGracePeriod && GRACE_PERIOD_ROUTES.includes(currentPath))
    );
  };

  const getRedirectPath = () => {
    if (!isAuthenticated) {
      return '/signin';
    }
    
    if (!subscription) {
      return '/subscribe';
    }
    
    const currentPath = getCurrentPath();
    
    // If subscription expired, send to subscription page
    if (subscription.status !== 'active') {
      return '/subscribe';
    }
    
    // If plan doesn't have access to this route, send to upgrade page
    if (!isValidSubscription()) {
      return `/upgrade?from=${encodeURIComponent(location.pathname)}`;
    }
    
    return currentPath;
  };

  // Special case: always allow access to subscribe page
  if (location.pathname === '/subscribe') {
    return children;
  }

  if (!isValidSubscription()) {
    // logger.info('SubscriptionGuard: Redirecting to', getRedirectPath());
    return <Navigate to={getRedirectPath()} state={{ from: location }} replace />;
  }

  return children;
};

export { MODEL_REQUIREMENTS };
export default SubscriptionGuard;