// providers/FeedbackProvider.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeedbackButton } from '../components/feedback/FeedbackButton';
import { FeedbackModal } from '../components/feedback/FeedbackModal';
import { submitFeedback } from '../store/thunks/feedbackThunks';
import logger from '../services/logger';

export const FeedbackProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (feedback) => {
    try {
      await dispatch(submitFeedback(feedback));
      setIsModalOpen(false);
    } catch (error) {
      // Error handling is already in the thunk
      logger.error('Failed to submit feedback:', error);
    }
  };

  return (
    <>
      {children}
      <FeedbackButton onClick={() => setIsModalOpen(true)} />
      <FeedbackModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};