// src/pages/Dashboard/index.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankAccounts } from '../../store/thunks/bankThunks';
import { sendChatMessage, fetchChatHistory } from '../../store/thunks/chatThunks';
import { selectBankAccounts, selectBankLoading } from '../../store/slices/bankSlice';
import { selectChatMessages, selectChatLoading, clearMessages, addMessage } from '../../store/slices/chatSlice';
import { selectChats } from '../../store/slices/chatHistorySlice';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import ChatHeader from './components/ChatHeader';
import ChatInput from './components/ChatInput';
import ChatMessages from './components/ChatMessages';
import ChatHistory from '../../components/chat/ChatHistory';
import { MODEL_REQUIREMENTS } from '../../components/guards/SubscriptionGuard';
import { toast } from 'react-hot-toast';
import { PLAN_FEATURES } from '../../config/planFeatures';
import logger from '../../services/logger';

// const AI_MODELS = [
//   { id: 'gpt-3.5-turbo', name: 'GPT-3.5' },
//   { id: 'gemini-pro', name: 'gemini' },
//   { id: 'grok-beta', name: 'Grok' },
//   { id: 'claude-3-sonnet-20240229', name: 'Claude' }
// ];
const AI_MODELS = [
  { id: 'gpt-3.5-turbo', name: 'GPT-3.5', provider: 'openai' },
  { id: 'gemini-pro', name: 'Gemini', provider: 'gemini' },  // Fixed
  { id: 'grok-beta', name: 'Grok', provider: 'openai' },
  { id: 'claude-3-sonnet-20240229', name: 'Claude', provider: 'claude' }
];
const Dashboard = () => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector(selectBankAccounts);
  const isBankLoading = useSelector(selectBankLoading);
  const messages = useSelector(selectChatMessages);
  const isChatLoading = useSelector(selectChatLoading);
  const subscription = useSelector(state => state.subscription);
  const chatHistoryLoading = useSelector(state => state.chatHistory.loading);
  const chats = useSelector(selectChats);
  
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [input, setInput] = useState('');
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

  // Add effect to persist date range
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      localStorage.setItem('chatDateRange', JSON.stringify(dateRange));
    }
  }, [dateRange]);

  // Load saved date range on mount
  useEffect(() => {
    const savedRange = localStorage.getItem('chatDateRange');
    if (savedRange) {
      setDateRange(JSON.parse(savedRange));
    }
  }, []);

  // Filter available models based on subscription
  const availableModels = AI_MODELS.filter(model => {
    const requiredPlans = MODEL_REQUIREMENTS[model.id];
    return requiredPlans?.includes(subscription.currentPlan);
  });

  // Set initial model after filtering available models
  useEffect(() => {
    if (!selectedModel && availableModels.length > 0) {
      setSelectedModel(availableModels[0]);
    }
  }, [availableModels, selectedModel]);

  const handleModelSelect = (model) => {
    const requiredPlans = MODEL_REQUIREMENTS[model.id];
    if (!requiredPlans?.includes(subscription.currentPlan)) {
      toast.error(`Your current plan doesn't have access to ${model.name}. Please upgrade your plan.`);
      return;
    }
    setSelectedModel(model);
  };

  // const chats = useSelector(state => {
  //   logger.info('Full Redux State:', state);
  //   return selectChats(state);
  // });

  // In Dashboard/index.js
  const handleHistoryClick = () => {
    // logger.info('History button clicked, current state:', isHistoryOpen);
    setIsHistoryOpen(prev => !prev);
    // logger.info('History state after toggle:', !isHistoryOpen);
  };

  useEffect(() => {
    // logger.info('Dashboard mounted');
    const loadData = async () => {
      try {
        // logger.info('Fetching chat history...');
        await dispatch(fetchChatHistory());
        // logger.info('Chat history fetched');
      } catch (error) {
        logger.error('Error loading chat history:', error);
      }
    };
    loadData();
  }, [dispatch]);

  useEffect(() => {
    logger.info('Chats updated:', chats);
  }, [chats]);

  useEffect(() => {
    dispatch(fetchBankAccounts()).catch((error) => {
      logger.error('Failed to fetch bank accounts:', error);
    });

    return () => {
      dispatch(clearMessages());
    };
  }, [dispatch]);

  const handleSelectChat = (chat) => {
    setCurrentChatId(chat.id);
    dispatch(clearMessages());
    // Filter out any null messages and ensure we're passing all message data
    const validMessages = chat.messages?.filter(msg => msg && msg.content) || [];
    validMessages.forEach(msg => {
      dispatch(addMessage({
        role: msg.role,
        content: msg.content,
        metadata: msg.metadata,
        provider: chat.model_provider // Add this to track the correct provider
      }));
    });
    
    // Update selected model based on chat's provider
    const matchingModel = AI_MODELS.find(m => m.provider === chat.model_provider);
    if (matchingModel) {
      setSelectedModel(matchingModel);
    }
    
    setIsHistoryOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || !selectedAccount) return;

    // Keep track of the original input for better UX
    const originalInput = input;
    setInput('');

    // Common message payload
    const messagePayload = {
      accountId: selectedAccount.id,
      modelId: selectedModel.id,
      modelProvider: selectedModel.provider,
      chatId: currentChatId,
      start_date: dateRange.startDate,  // Change this
      end_date: dateRange.endDate      // Change this
  };

    try {
        // Split input if it contains multiple questions
        const questions = originalInput.split('?')
            .filter(q => q.trim())
            .map(q => q.trim() + '?');

        // If it's a single question or not a question at all
        if (questions.length <= 1) {
            dispatch(addMessage({ role: 'user', content: originalInput }));
            await dispatch(sendChatMessage({
                ...messagePayload,
                message: originalInput
            }));
        } else {
            // Handle multiple questions sequentially
            for (const question of questions) {
                dispatch(addMessage({ role: 'user', content: question }));
                await dispatch(sendChatMessage({
                    ...messagePayload,
                    message: question
                }));
            }
        }
    } catch (error) {
        logger.error('Failed to send message:', error);
        // Optionally restore input on error
        setInput(originalInput);
        toast.error('Failed to send message. Please try again.');
    }
  };

   // Helper function to get plan limits
   const getPlanLimits = () => {
    const plan = PLAN_FEATURES[subscription?.currentPlan || 'basic'];
    if (subscription?.status === 'trialing' && plan?.trial?.limits) {
      return plan.trial.limits;
    }
    return plan?.limits || {
      requests_per_day: 5,
      monthly_queries: 100
    };
  };

  // Add these new checks
  const isTrialExpired = subscription?.status === 'trialing' && 
    new Date(subscription?.currentPeriodEnd) < new Date();

  const hasDailyLimit = chats?.filter(chat => {
    const chatDate = new Date(chat.created_at);
    const today = new Date();
    return chatDate.toDateString() === today.toDateString();
  }).length >= getPlanLimits().requests_per_day;

  const hasMonthlyLimit = chats?.length >= getPlanLimits().monthly_queries;

 

  // In Dashboard component
  const getInputDisabledReason = () => {
    if (!selectedAccount) return "Please select a bank account first";
    // Add this new check for subscription requiring renewal
    if (subscription?.status === 'requires_payment' || subscription?.status === 'past_due') {
      return "Your subscription needs renewal. Please renew your subscription to continue.";
    }
    if (isTrialExpired) return "Your trial has expired. Please add a payment method to continue.";
    if (hasDailyLimit) return "You've reached your daily chat limit. Upgrade your plan for more.";
    if (hasMonthlyLimit) return "You've reached your monthly chat limit. Upgrade your plan for more.";
    if (isChatLoading) return "Please wait...";
    return null;
  };

  const isInputDisabled = !selectedAccount || 
    isChatLoading || 
    isTrialExpired || 
    hasDailyLimit || 
    hasMonthlyLimit ||
    // Add this check? why?
    subscription?.status === 'requires_payment' || 
    subscription?.status === 'past_due';

  return (
    <DashboardLayout>
      {/* Change bg-gray-50 to include dark variant */}
      <div className="h-[calc(100vh-64px)] flex flex-col bg-gray-50 dark:bg-gray-900">
        <ChatHeader
          selectedAccount={selectedAccount}
          selectedModel={selectedModel}
          onAccountSelect={setSelectedAccount}
          onModelSelect={handleModelSelect}
          accounts={bankAccounts}
          models={availableModels}
          currentPlan={subscription.currentPlan}
          isLoading={isBankLoading || chatHistoryLoading}
          onHistoryClick={handleHistoryClick}
        />
        
        <ChatHistory
          isOpen={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
          chats={chats}
          onSelectChat={handleSelectChat}
          isLoading={isBankLoading || chatHistoryLoading}
        />
  
        {/* Change the flex container to include dark background */}
        <div className="flex-1 max-w-4xl mx-auto w-full flex flex-col h-full">
          {/* Message container with dark mode */}
          <div className="flex-1 overflow-y-auto bg-white dark:bg-gray-900">
            <ChatMessages messages={messages} isLoading={isChatLoading} />
          </div>
          
          {/* Input container with dark mode */}
          <div className="mt-auto border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 p-4">
          <ChatInput
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSubmit={handleSubmit}
            isDisabled={isInputDisabled}
            disabledReason={getInputDisabledReason()}
            placeholder={selectedAccount ? "Ask about your finances..." : "Please select a bank account first"}
            onDateRangeChange={setDateRange}
          />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;